import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useParams, Navigate } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import About from './About';
import Support from './Support';
import privilegeBg from './assets/privilege-bg.png';
import './App.css';
import Footer from './components/Footer';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <MainContent />
    </Router>
  );
}

function PlanDeepLink() {
  const { id } = useParams();
  const [redirectUrl, setRedirectUrl] = React.useState<string | null>(null);

  React.useEffect(() => {
    const handleDeepLink = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isAndroid = /android/.test(userAgent);
      const isIOS = /iphone|ipad|ipod/.test(userAgent);
      const isMobile = isAndroid || isIOS;
      console.log('isMobile', isMobile);
      console.log('isAndroid', isAndroid);
      console.log('isIOS', isIOS);
      if (isMobile) {
        // Try to open app first
        const appUrl = isIOS
          ? `privilege://${id ? `bubblesfeed/${id}` : ''}`
          : `privilege://app/${id ? `bubblesfeed/${id}` : ''}`;

        // Store URLs
        const iosStore = 'https://apps.apple.com/us/app/privilege-nightlife/id6448764643';
        const androidStore = 'https://play.google.com/store/apps/details?id=your.app.id';

        // Try to open app, fallback to store if app isn't installed
        const timeout = setTimeout(() => {
          window.location.href = isIOS ? iosStore : androidStore;
        }, 2500);
        console.log('appUrl', appUrl);
        window.location.href = appUrl;
      
        // Clear timeout if user switches away from browser
        window.onblur = () => clearTimeout(timeout);
        // setRedirectUrl('/');
        setTimeout(() => {
          setRedirectUrl('/');
        }
        , 4000);
      } else {
        // For web, redirect to home
        setRedirectUrl('/');
      }
    };

    handleDeepLink();
  }, [id]);

  if (redirectUrl) {
    window.location.href = redirectUrl;
  }

  return null;
}

function Download() {
  React.useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = /android/.test(userAgent);
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    
    const iosStore = 'https://apps.apple.com/us/app/privilege-nightlife/id6448764643';
    const androidStore = 'https://play.google.com/store/apps/details?id=your.app.id';
    
    if (isAndroid) {
      window.location.href = androidStore;
    } else if (isIOS) {
      window.location.href = iosStore;
    } else {
      // If desktop, default to iOS store
      window.location.href = iosStore;
    }

    // Return to home after a short delay
    setTimeout(() => {
      window.location.href = '/';
    }, 3000);
  }, []);

  return null;
}

function MainContent() {
  const location = useLocation();
  const getPageTitle = () => {
    switch (location.pathname) {
      case '/': return 'Privilege';
      case '/about': return 'About Us';
      case '/support': return 'Support';
      case '/privacy': return 'Privacy Policy';
      case '/terms': return 'Terms of Service';
      default: return '';
    }
  };

  return (
    <div className="app-container">
      <div className="background-layer" style={{ backgroundImage: `url(${privilegeBg})` }} />
      <ScrollToTop />
      <Navbar />
      <main className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/support" element={<Support />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/plan/:id" element={<PlanDeepLink />} />
          <Route path="/download" element={<Download />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
